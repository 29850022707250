import React from "react";
import { Button } from "antd";
import PropTypes, { Requireable } from "prop-types";
import { useTranslation } from "react-i18next";

type ButtonProps = {
  theme?: "primary" | "secondary";
  disabled?: boolean;
  children: React.ReactNode;
  primaryColor?: {
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  };
  secondaryColor?: {
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  };
  height?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className: string;
};

const PrimaryButton: React.FC<ButtonProps> = ({
  theme = "primary",
  disabled = false,
  children,
  primaryColor = {},
  secondaryColor = {},
  className = "",
  height = "40px",
  onClick,
  ...props
}) => {
  // Added onClick to props
  // Define styles for different themes
  const primaryStyles: React.CSSProperties = {
    backgroundColor: primaryColor?.backgroundColor || "#1D4E89",
    color: primaryColor?.color || "#FFFFFF",
    borderColor: primaryColor?.borderColor || "#1D4E89",
    height: height,
  };
  const { t } = useTranslation();
  const secondaryStyles: React.CSSProperties = {
    backgroundColor: secondaryColor?.backgroundColor || "transparent",
    color: secondaryColor?.color || "#0298A0",
    borderColor: secondaryColor?.borderColor || "#0298A0",
    height: height,
  };

  const disabledStyles: React.CSSProperties = {
    backgroundColor: "#737373",
    color: "#BFBFBF",
    borderColor: "#737373",
    height: height,
  };

  const disabledSecondaryStyles: React.CSSProperties = {
    backgroundColor: "transparent",
    color: "#737373",
    borderColor: "#737373",
    height: height,
  };
  // Apply styles based on theme and disabled state
  const buttonStyles: React.CSSProperties =
    disabled && theme === "primary"
      ? disabledStyles
      : disabled && theme === "secondary"
      ? disabledSecondaryStyles
      : theme === "primary"
      ? primaryStyles
      : secondaryStyles;

  return (
    <Button
      style={buttonStyles}
      disabled={disabled}
      onClick={onClick}
      {...props}
      className={className}
    >
      {children}
    </Button>
  );
};

PrimaryButton.propTypes = {
  theme: PropTypes.oneOf(["primary", "secondary"]),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  primaryColor: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
  }) as Requireable<{
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  }>,
  secondaryColor: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
  }) as Requireable<{
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  }>,
  height: PropTypes.string,
  onClick: PropTypes.func, // Added onClick prop type
};

export default PrimaryButton;
