import Cookies from "js-cookie";
import axios from "axios";

export const logout = async () => {
  const accessToken =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("accessToken")
      : Cookies.get('accessToken')
  // Rest of the logout function code...
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/identity/user/logout`, {},
      {
        headers: headers,
      }
    );
    if (response?.status === 200) {
      window.location.assign("/");
      localStorage.setItem("languageSelected", "en");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("userMenu");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("company_id");
      localStorage.removeItem("vat");
      localStorage.removeItem("company_name");
      localStorage.removeItem("refPart");
      localStorage.removeItem("refPartId");
      localStorage.removeItem("isRefMaterial");
      localStorage.removeItem("isRefMaterials");
      localStorage.removeItem("refPartIds");
      localStorage.removeItem("productId");
      
      Cookies.remove("accessToken");
      
    }

    // Handle the successful response
  } catch (error: any) {
    if (error?.response?.data?.statusCode === 401) {
      window.location.assign("/");
      localStorage.setItem("languageSelected", "en");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("userMenu");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("company_id");
      localStorage.removeItem("vat");
      localStorage.removeItem("company_name");
      localStorage.removeItem("refPart");
      localStorage.removeItem("refPartId");
      localStorage.removeItem("isRefMaterial");
      Cookies.remove("accessToken");
    }
    // Handle errors
    // if (error?.message?.toLowerCase() === "network error") {
    //   const timer = setTimeout(() => {
    //     // Handle the Alert after 5 seconds
    //   }, 5000);

    //   return () => {
    //     clearTimeout(timer);
    //   };
    // }
  }
};
