import axios, { AxiosRequestConfig } from "axios";
import { logout } from "../logoutUtil";

interface ServiceUtilResponse {
  data: any;
  error: any;
  status?: number;
}

const getDefaultHeaders = () => {
  const accessToken =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('accessToken')
      : '';

  if (!accessToken) {
    logout();
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

const serviceUtil = {
  get: async (url: string, params: any, headers?: AxiosRequestConfig["headers"], isAuth: boolean = false) => {
    try {
      const mergedHeaders = !isAuth
        ? { ...getDefaultHeaders(), ...headers }
        : headers;

      const response = await axios.get(url, {
        params,
        headers: mergedHeaders,
      });
      return { data: response.data, error: null, status: response.status } as ServiceUtilResponse;
    } catch (error: any) {
      console.error(error);
      if(error?.response?.status === 401){
        logout()
      }
      return { data: null, error } as ServiceUtilResponse;
    }
  },
  post: async (url: string, data: any, headers?: AxiosRequestConfig["headers"], isAuth: boolean = false) => {
    try {
      const mergedHeaders = !isAuth
        ? { ...getDefaultHeaders(), ...headers }
        : headers;

      const response = await axios.post(url, data, {
        headers: mergedHeaders,
      });
      return { data: response.data, error: null, status: response.status } as ServiceUtilResponse;
    } catch (error: any) {
      console.error(error);
      if(error?.response?.status === 401){
        logout()
      }
      return { data: null, error } as ServiceUtilResponse;
    }
  },
  put: async (url: string, data: any, headers?: AxiosRequestConfig["headers"], isAuth: boolean = false) => {
    try {
      // Merge default headers with provided headers if isAuth is false
      const mergedHeaders = !isAuth
        ? { ...getDefaultHeaders(), ...headers }
        : headers;

      const response = await axios.put(url, data, {
        headers: mergedHeaders,
      });
      return { data: response.data, error: null, status: response.status } as ServiceUtilResponse;
    } catch (error: any) {
      console.error(error);
      if(error?.response?.status === 401){
        logout()
      }
      return { data: null, error } as ServiceUtilResponse;
    }
  },
  delete: async (url: string, headers?: AxiosRequestConfig["headers"], isAuth: boolean = false) => {
    try {
      // Merge default headers with provided headers if isAuth is false
      const mergedHeaders = !isAuth
        ? { ...getDefaultHeaders(), ...headers }
        : headers;

      const response = await axios.delete(url, {
        headers: mergedHeaders,
      });
      return { data: response.data, error: null, status: response.status } as ServiceUtilResponse;
    } catch (error: any) {
      console.error(error);
      if(error?.response?.status === 401){
        logout()
      }
      return { data: null, error } as ServiceUtilResponse;
    }
  },
};

export default serviceUtil;
